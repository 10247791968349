<template>
	<div id="create-modal">
		<div v-if="!!serverBusy" id="server-activity-loading">
			<span class="loading-icon"></span>
			<span class="loading-message">{{ busyText }}</span>
		</div>
		<div id="create-container">
			<fieldset>
				<legend>Create New Casino</legend>
				<p>
					Casinos should not be created or deleted casually. They continue to exist and take up space in the database forever, even when
					deleted.
				</p>
				<div class="label-input">
					<label for="casino-name" title="Casino Name (required)">Casino Name</label>
					<input
						type="text"
						id="casino-name"
						name="casino-name"
						placeholder="Casino Name (required)"
						title="Casino Name (required)"
						v-model="name"
					/>
				</div>
				<div class="label-input">
					<label for="description" title="Casino description">Casino description</label>
					<textarea id="description" name="description" placeholder="Description" title="Description" v-model="description"></textarea>
				</div>
				<div class="button-group">
					<button class="btn" type="button" @click="createCasino()" title="Create New Casino">Create New Casino</button>
					<button type="button" class="btn" @click="closeModal()" :title="closeButtonText">{{ closeButtonText }}</button>
				</div>
			</fieldset>
		</div>
	</div>
</template>

<script>
import { onBeforeUnmount } from "vue";
import sharedScripts from "@/dependencies/sharedScripts";

export default {
	name: "CreateCasino",
	inheritAttrs: false,
	props: {
		adminState: Object,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			serverBusy: false,
			closeButtonText: "Close",
			charactersRemaining: 512,
			busyText: "",
			description: "",
			name: "",
		};
	},
	watch: {
		description() {
			this.closeButtonText = "Cancel";
		},
		name() {
			this.closeButtonText = "Cancel";
		},
	},
	created() {},
	mounted() {},
	methods: {
		closeModal() {
			this.eventBus.emit("closeCreateCasinoModal");
		},
		async createCasino() {
			this.busyText = "Creating New Casino";
			this.serverBusy = true;
			let errorState = false;

			// Rabbitsfoot server deos not require Name but for practical reasons we are forcing
			// the user to input a Name. There is no check for uniqueness of the Name.
			if (!this.name) {
				errorState = true;
				this.status.ok = false;
				this.status.message = "Casino name is required.";
				this.eventBus.emit("updateStatus", this.status);
			}

			if (errorState) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			this.busyText = "Creating New Casino";
			this.serverBusy = true;
			this.status.ok = true;

			let body = {
				name: this.name,
				description: this.description,
			};

			let requestUrl = new URL("/api/v1/site/", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.adminState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				body: JSON.stringify(body),
				headers: headerObj,
			});

			try {
				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverBusy = false;
					this.busyText = "";
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout) this.eventBus.emit("forceLogout");
					return false;
				}

				let dataJson = await response.json();

				this.status.message = `${this.name} created successfully.`;
				this.status.code = fetchStatus.code;
				this.status.ok = fetchStatus.ok;
				this.eventBus.emit("updateStatus", this.status);
				this.serverBusy = false;
				this.busyText = "";
				this.eventBus.emit("updateCasinoList");
				this.eventBus.emit("updateCasinosIncludingDeleted", this.status.ok);
				this.closeModal();
			} catch (e) {
				this.status.code = e;
				this.status.ok = false;
				this.userMustDismiss = true;
				this.eventBus.emit("updateStatus", this.status);
				console.error(e);
				return false;
			}
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#server-activity-loading {
	display: grid;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 30px;
	z-index: 9999;
	transition: background-color 0.3s ease-in-out;
	background-color: rgb(0 0 0 / 80%);
	color: #fff;
}

#server-activity-loading {
	text-align: center;
	align-content: center;
}

#create-modal {
	display: grid;
	height: 100vh;
	background-color: rgb(0 0 0 / 75%);
	backdrop-filter: blur(6px);
}

#create-container {
	display: flex;
	flex-flow: column nowrap;
	position: relative;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: #222;
	color: #fff;
	width: 90%;
	margin: auto;
	padding: 30px;
	border-radius: 12px;
	text-align: center;
}

.loading-message {
	margin-top: 15px;
}

.input-group {
	margin: auto;
	width: 100%;
	min-width: 10em;
}

.label-input {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
}

.label-input label,
.input-group .playable-label {
	max-width: 10em;
	flex: 0 1 auto;
	text-align: right;
}

.input-group input {
	flex: 0 1 auto;
}

#description,
#casino-name {
	flex: 1 1 auto;
}

fieldset p {
	text-align: left;
}

#create-container p {
    color: #ff0;
}

.button-group {
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	margin-top: 10px;
}

@media (min-width: 768px) {
	#create-container {
		width: 50%;
	}

	.input-section fieldset {
		padding: 0.75em;
	}

	.label-input label,
	.input-group .playable-label {
		flex: 1 1 auto;
	}

	.input-group input {
		flex: 1 1 50%;
	}

	.label-input {
		flex-flow: row nowrap;
	}
}
</style>
