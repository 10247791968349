<template>
	<div class="delete">
		<div v-if="!!serverBusy" id="delete-loading">
			<span class="loading-icon"></span>
			<span class="loading-message">{{ busyText }}</span>
		</div>
		<h1>Create/Delete Casino</h1>
		<button class="btn" id="create-button" title="Create Casino" @click="createCasinoModal()">Create Casino</button>
		<div v-if="adminState?.isLoggedOn">
			<div class="deleted-lists-container" v-if="casinosIncludingDeleted?.length > 0">
				<table class="deleted">
					<tr class="header-row">
						<th colspan="3">Casinos</th>
					</tr>
					<tr class="header-row">
						<th>Casino ID</th>
						<th>Casino Name</th>
						<th>{{ isMobile ? "Delete / Undelete" : "Delete/Undelete" }}</th>
					</tr>
					<tr class="data-row" v-for="(item, index) in casinosIncludingDeleted" :key="index" :value="item.id">
						<td>{{ item.id }}</td>
						<td>
							{{ item.name }}<span class="red-text">{{ item.isEnabled ? "" : "disabled" }}</span>
						</td>
						<button class="btn red-bg" v-if="!item.isDeleted" @click="deleteSafetyCheck(item)">Delete</button>
						<button class="btn" v-if="item.isDeleted" @click="setCasinoDeleteState(item, false)">Undelete</button>
					</tr>
				</table>
			</div>
			<div v-else>
				<h2>No casinos found.</h2>
			</div>
		</div>
	</div>
	<transition name="fade">
		<component
			v-bind:is="currentModal"
			:adminState="adminState"
			:casinoToDelete="casinoToDelete"
			:safetyCheckData="safetyCheckData"
			:serverStatus="serverStatus"
		/>
	</transition>
</template>

<script>
import { onBeforeUnmount } from "vue";
import sharedScripts from "@/dependencies/sharedScripts";
import DeleteCasinoModal from "@/components/DeleteCasinoModal";
import CreateCasino from "@/components/CreateCasino";

export default {
	name: "CreateOrDeleteCasino",
	inheritAttrs: false,
	components: {
		DeleteCasinoModal,
		CreateCasino,
	},
	props: {
		casinosIncludingDeleted: Array,
		deletedCasinos: Array,
		adminState: Object,
		isMobile: Boolean,
		inGameCurrencyTool: Object,
		systemCurrencyTool: Object,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			body: document.querySelector("body"),
			serverBusy: false,
			busyText: "",
			safetyCheckData: {},
			currentModal: null,
			casinoToDelete: null,
		};
	},
	created() {
		this.eventBus.emit("updateCasinosIncludingDeleted");
		this.eventBus.on("closeDeleteCasinoModal", () => {
			this.currentModal = null;
		});
		this.eventBus.on("deleteSelectedCasino", (payload) => {
			this.currentModal = null;
			this.setCasinoDeleteState(payload, true);
		});
		this.eventBus.on("closeCreateCasinoModal", () => {
			this.currentModal = null;
		});
		onBeforeUnmount(() => {
			this.body.onkeydown = null;
			this.eventBus.off("closeDeleteCasinoModal");
			this.eventBus.off("deleteSelectedCasino");
			this.eventBus.off("closeCreateCasinoModal");
		});
	},
	mounted() {
		this.body.onkeydown = (e) => {
			if (e.key === "Escape") this.currentModal = null;
		};
	},
	methods: {
		createCasinoModal() {
			this.currentModal = "CreateCasino";
		},
		async setCasinoDeleteState(casino, shouldBeDeleted) {
			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			this.busyText = "Saving";
			this.serverBusy = true;
			this.status.ok = true;

			let body = {
				casinoId: casino.id,
				shouldBeDeleted: shouldBeDeleted,
			};

			let requestUrl = new URL("/api/v1/site/delete", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.adminState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				body: JSON.stringify(body),
				headers: headerObj,
			});

			try {
				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverBusy = false;
					this.busyText = "";
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout) this.eventBus.emit("forceLogout");
					return false;
				}

				let dataJson = await response.json();

				// Check if we are deleting the casino the user is logged into
				let currentCasinoDeleted = dataJson.id === this.adminState?.casinoId;
				let deleteConflict = shouldBeDeleted && currentCasinoDeleted;

				this.status.message = `${casino.name} ${shouldBeDeleted ? "deleted" : "restored"} successfully.`;
				this.status.code = fetchStatus.code;
				this.status.ok = fetchStatus.ok;
				this.eventBus.emit("updateStatus", this.status);

				if (deleteConflict) {
					this.status.message = `You were logged out of ${this.adminState.casinoName}. You must log into the Admin Panel again.`;
					this.status.code = 404;
					this.status.ok = false;
					this.eventBus.emit("updateStatus", this.status);
					this.eventBus.emit("forceLogout");
				} else {
					this.eventBus.emit("updateCasinosIncludingDeleted");
				}

				this.eventBus.emit("updateCasinoList");

				this.serverBusy = false;
				this.busyText = "";
			} catch (e) {
				this.status.code = e;
				this.status.ok = false;
				this.userMustDismiss = true;
				this.eventBus.emit("updateStatus", this.status);
				console.error(e);
				return false;
			}
		},
		async deleteSafetyCheck(casino) {
			// Check component's if server is not already refreshing access token. Return if true.
			if (this.refreshTokenAwait) return;
			this.serverStatus.busyText = `Safety checking before deleting ${casino.name}.`;
			this.serverStatus.serverBusy = true;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.adminState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let requestUrl = new URL(`/api/v1/site/delete/${casino.id}`, this.rabbitsfootHostUrl);

			let request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverStatus.serverBusy = false;
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout) this.eventBus.emit("forceLogout");
					return false;
				}

				let dataJson = await response.json();

				this.safetyCheckData = dataJson;

				this.casinoToDelete = casino;
				this.currentModal = "DeleteCasinoModal";

				this.serverStatus.serverBusy = false;
				this.serverStatus.busyText = "";
			} catch (e) {
				this.serverStatus.serverBusy = false;
				this.serverStatus.busyText = "";
				this.status.ok = false;
				this.status.message = e;
				console.error(e);
			}
		},
	},
};
</script>

<style scoped>
#delete-loading {
	position: absolute;
	display: grid;
	align-content: center;
	width: 100%;
	height: 100%;
	z-index: 100;
	background: rgb(0 0 0 / 70%);
	-webkit-backdrop-filter: blur(6px);
	backdrop-filter: blur(6px);
}

.deleted {
	position: relative;
	width: auto;
	margin: auto auto 15px;
	border-collapse: collapse;
	text-align: center;
	color: #000;
	background-color: #d7d7d7;
	box-shadow: 0px 6px 10px 0px rgb(0 0 0 / 50%);
}

.delete {
	color: #fff;
	height: calc(100vh - 150px);
	margin-top: 0;
	top: 98px;
	position: fixed;
	width: 95%;
	margin: 0 2.5% auto;
	overflow: auto;
}

.deleted tr {
	padding: 5px 10px;
	width: 33%;
	border: 1px #000 solid;
}

.deleted-lists-container {
	/* height: calc(100vh - 23em); */
	overflow: hidden auto;
}

.deleted * {
	padding: 5px 15px;
}

.deleted tr {
	transition: background-color 0.3s;
}

.delete-container table {
	text-align: left;
}

#create-button {
	display: block;
	margin: auto auto 15px;
}

tr.header-row {
	background-color: #000;
	color: #fff;
	border-bottom: 1px solid #fff;
}

.deleted tr.data-row {
	background-color: #aaa;
}
.deleted tr.data-row:nth-child(2n) {
	background-color: #bbb;
}

.deleted tr.data-row:hover {
	background-color: #fff;
	color: #000;
}

.true {
	background-color: #080;
	color: #fff;
}

.false {
	background-color: #646464;
	color: #969696;
}

.deleted-container p {
	width: 60%;
	padding: 15px 30px;
	margin: auto;
	background-color: rgb(67 66 80 / 50%);
	backdrop-filter: blur(3px);
	color: #ff0;
	text-align: center;
	font-weight: bold;
}

.deleted-container p:first-child {
	margin-top: 15px;
}

.deleted-container p + p {
	padding-top: 0 !important;
}

@media (min-width: 768px) {
	.delete {
		width: 80%;
		margin-left: 20%;
	}
}
</style>
