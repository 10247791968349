<template>
	<div class="delete-text">
		<div v-if="serverStatus.serverBusy" id="delete-loading">
			<span class="loading-icon"></span>
			<span class="loading-message">{{ serverStatus.busyText }}</span>
		</div>
		<div class="delete-container">
			<fieldset>
				<legend>Delete Safety Check</legend>
				<h3 class="delete-h3">{{ casinoToDelete.name }}</h3>
				<p class="yellow-text">
					Casinos should not be created or deleted casually. They continue to exist and take up space in the database forever, even when
					deleted.
				</p>
				<table>
					<tr>
						<th class="header-row">Casino Id</th>
						<td class="data-row">{{ safetyCheckData.casinoId }}</td>
					</tr>
					<tr>
						<th class="header-row">Player Accounts Count</th>
						<td class="data-row">{{ safetyCheckData.playerAccountsCount }}</td>
					</tr>
					<tr>
						<th class="header-row">Open Cashier Banks Count</th>
						<td class="data-row">{{ safetyCheckData.openCashierBanksCount }}</td>
					</tr>
					<tr>
						<th class="header-row">Player Accounts Total Regular</th>
						<td class="data-row">{{ toLocaleCurrencyIntl(safetyCheckData.playerAccountsTotalRegularAU) }}</td>
					</tr>
					<tr>
						<th class="header-row">Progressive Pot Total</th>
						<td class="data-row">{{ toLocaleCurrencyIntl(safetyCheckData.progressivePotTotalAU) }}</td>
					</tr>

					<tr>
						<th class="header-row">Earliest Play Summary Date</th>
						<td class="data-row">{{ earliestPlaySummaryDate }}</td>
					</tr>
					<tr>
						<th class="header-row">Latest Play Summary Date</th>
						<td class="data-row">{{ latestPlaySummaryDate }}</td>
					</tr>
				</table>
				<p v-if="currentCasinoDeleted" class="yellow-text">
					<span>{{ conflictMsgFirst }}</span>
					<br />
					<span>{{ conflictMsgSecond }}</span>
				</p>
				<div class="flex">
					<button class="btn red-bg" @click="showDeleteDialog()">Delete This Casino</button>
					<button class="btn" @click="closeModal()">Cancel</button>
				</div>
			</fieldset>
		</div>
	</div>
	<dialog id="delete-dialog">
		<div>
			<p id="dialog-text">
				{{confirmMsg}}
			</p>
			<div id="btn-container">
				<button id="confirmButton" class="btn" value="default" @click="sendDeleteCasinoMessage()">Ok</button>
				<button id="cancelButton" class="btn" value="cancel" formmethod="dialog" @click="closeDeleteModal()">Cancel</button>
			</div>
		</div>
	</dialog>
</template>

<script>
export default {
	name: "DeleteCasinoModal",
	props: {
		adminState: Object,
		casinoToDelete: Object,
		safetyCheckData: Object,
		serverStatus: Object,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			earliestPlaySummaryDate: this.safetyCheckData.earliestPlaySummaryDate
				? new Date(this.safetyCheckData.earliestPlaySummaryDate).toLocaleTimeString([], this.shortDateOptions)
				: "No play summary found",
			latestPlaySummaryDate: this.safetyCheckData.latestPlaySummaryDate
				? new Date(this.safetyCheckData.latestPlaySummaryDate).toLocaleTimeString([], this.shortDateOptions)
				: "No play summary found",
			currentCasinoDeleted: this.casinoToDelete.id === this.adminState?.casinoId,
			conflictMsgFirst: "You are currently logged into the casino you are about to delete.",
			conflictMsgSecond: "If you continue, you will also be logged out of this casino.",
			inputDialog: null,
			confirmMsg: "",
		};
	},
	mounted(){
		this.inputDialog = document.getElementById("delete-dialog");
	},
	methods: {
		closeModal() {
			this.eventBus.emit("closeDeleteCasinoModal");
		},
		sendDeleteCasinoMessage() {
			this.eventBus.emit("deleteSelectedCasino", this.casinoToDelete);
			this.eventBus.emit("updateCasinosIncludingDeleted");
			this.closeDeleteModal();
		},

		showDeleteDialog() {
			this.confirmMsg = `${
				this.currentCasinoDeleted ? this.conflictMsgFirst + "\n" + this.conflictMsgSecond + "\n" : ""
			}Are you sure you want to delete ${this.casinoToDelete.name}?`;
			this.inputDialog.showModal();
		},

		closeDeleteModal() {
			this.inputDialog.close();
		}
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#delete-loading {
	position: absolute;
	display: grid;
	align-content: center;
	width: 100%;
	height: 100%;
	z-index: 100;
	background: rgb(0 0 0 / 70%);
	-webkit-backdrop-filter: blur(6px);
	backdrop-filter: blur(6px);
}

.delete-text {
	display: grid;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgb(0 0 0 / 70%);
	backdrop-filter: blur(5px);
	z-index: 100;
}

.delete-container {
	display: flex;
	flex-flow: column nowrap;
	position: relative;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: #222;
	color: #fff;
	width: 80%;
	margin: auto;
	padding: 30px;
	border-radius: 12px;
	text-align: center;
}

.delete-container fieldset {
	display: flex;
	flex-flow: column;
	padding: 30px;
}

.delete-container p,
.delete-container table {
	text-align: left;
}

.delete-container button {
	margin: 5px auto;
}

th,
.header-row {
	text-transform: capitalize;
}

.data-row {
	text-align: right;
}

p.yellow-text {
    text-align: center;
}

.commit {
	background-color: #008000;
}

.cancel {
	background-color: #f00;
}

.flex {
	display: flex;
	flex-direction: row;
}

.delete-h3 {
	margin: 0;
}

#delete-dialog {
	margin: auto 15px;
	background-color: #000;
	color: #fff;
	border-radius: 12px;
	border-width: 1px;
}

::backdrop {
	margin: auto 15px;
	background-color: #000;
	backdrop-filter: blur(8px);
	opacity: 0.75;
}

#btn-container {
	display: flex;
	justify-content: center;

	.btn {
		min-width: 6em;
		min-height: 2.5em;
	}
}

#delete-dialog {
		margin: auto;
}

#dialog-text {
	text-align: center;
}

@media (min-width: 768px) {
	.delete-container {
		width: 50%;
	}
}
</style>
